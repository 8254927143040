body,
html {
  font-family:
    Poppins,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    'sans-serif';
  width: 100% !important;
  background-color: #fff;
}

.container {
  margin: 0 auto;
  width: 92rem;
}

.ant-form,
.ant-divider-horizontal.ant-divider-with-text {
  color: #888 !important;
}
.ant-form-item {
  .ant-form-item-explain {
    display: none;
  }
  .ant-form-item-label {
    label {
      color: #888;
    }
  }
  .ant-form-item-control {
    .ant-form-item-control-input-content {
      & > input,
      & > .ant-input-affix-wrapper,
      & > .ant-input-password {
        height: 42px;
        border-radius: 12px;
      }
      .ant-input-prefix {
        margin-right: 8px;
        font-size: 22px;
      }
    }
  }
}

@media (max-width: 640px) {
  .ant-divider-horizontal.ant-divider-with-text,
  .ant-btn,
  .ant-message {
    font-size: 3.75rem !important;
  }

  .ant-form-item {
    margin-bottom: 4.375rem;
    .ant-form-item-explain {
      font-size: 3.125rem;
    }
    .ant-form-item-label {
      label {
        font-size: 3.75rem;
      }
    }
    .ant-form-item-control {
      .ant-form-item-control-input-content {
        & > input,
        & > .ant-input-affix-wrapper,
        & > .ant-input-password {
          height: 10rem;
          border-radius: 2.5rem;
          font-size: 3.75rem;
          input {
            font-size: 3.75rem;
          }
        }
        .ant-input-prefix {
          margin-right: 2.5rem;
        }
      }
    }
  }
}
