.header-container {
  padding: 1rem 0;
  .header-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-left {
      .header-logo {
        display: block;
        height: 32px;
        cursor: pointer;
      }
    }
    .header-toggler {
      display: none;
    }
    .header-right {
      display: flex;
      .header-nav {
        align-items: center;
        column-gap: 3rem;
        display: flex;
        flex-direction: row;
        .nav-link {
          color: #2ba245;
          font-weight: 700;
          font-size: 14px;
        }
        .signin-btn {
          width: 120px;
          height: 36px;
          background: #2ba245;
          color: #f1f1f1;
          border: 1px solid #2ba245;
          border-radius: 4px;
          box-shadow: none;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          cursor: pointer;
          font-size: 16px;
          &:hover {
            border-color: #1aad19;
            background: #1aad19;
            color: #fff;
          }
        }
        .avatar {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
        .logout-btn {
          color: red !important;
        }
        .logout-btn.isSignin,
        .userInfo.isSignin,
        .signin-btn.isSignin {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 640px) {
  .header-container {
    padding: 2rem 0;
    .header-wrap {
      .header-left {
        .header-logo {
          height: 22px;
        }
      }
      .header-right {
        flex: 1;
        justify-content: flex-end;
        .header-toggler {
          display: flex;
          align-items: center;
          color: #2ba245;
          font-size: 20px;
          height: 26px;
          .menu-line1 {
            transform: rotate(44deg) translate(6px, -8px);
            transform-origin: 10px 25px;
            transition: all 0.35s ease;
          }
          .menu-line2 {
            transform: rotate(-44deg) translate(-34px, 6px);
            transform-origin: 10px 25px;
            transition: all 0.35s ease;
          }
          &.collapsed {
            .menu-line1,
            .menu-line2 {
              transform: rotate(-0deg) translate(0px, 0px);
            }
          }
        }
        .header-nav {
          position: fixed !important;
          z-index: 999;
          width: 66vw !important;
          height: 100vh !important;
          top: 0;
          right: 0;
          background: #fff !important;
          transition: all 0.35s ease;
          display: flex;
          flex-direction: column;
          transform: translateX(0);
          box-shadow: rgba(44, 62, 80, 0.35) 0px 0px 70px 0px;
          align-items: unset !important;
          .googleTranslate-link {
            position: absolute;
            top: -2px;
            left: 0;
            margin-left: 16px !important;
          }
          &.collapsed {
            transform: translateX(100%);
            box-shadow: none;
          }
          .header-toggler {
            height: calc(26px + 4rem);
            justify-content: end;
            margin-right: 4rem;
          }
          .nav-link {
            height: 52px;
            display: flex;
            align-items: center;
            font-size: 16px;
            margin: 0 30px;
            border-bottom: 1px solid hsla(0, 0%, 100%, 0.08);
            &.signin-btn {
              color: #2ba245;
              background-color: transparent;
              border: none;
              margin-left: 30px;
              padding-left: 0px;
              width: 100% !important;
              text-align: left !important;
              display: flex;
              justify-content: flex-start !important;
            }
          }
        }
        .avatar {
          display: none;
        }
        .logout-btn.isSignin,
        .userInfo.isSignin,
        .signin-btn.isSignin {
          display: flex !important;
        }
      }
    }
  }
}

#goog-gt- {
  display: none !important;
}
#google_translate_element {
  display: none;
  height: 26px;
  border: 1px solid #dfe0e0;
  border-radius: 16px;
  overflow: hidden;
  padding: 2px 6px;

  background-color: #fff;
  .goog-te-gadget-simple {
    display: flex !important;
    border: none !important;
    align-items: center;
    .goog-te-gadget-icon {
      width: 14px;
      height: 14px;
    }
    a {
      font-size: 12px !important;
      font-weight: 400 !important;
    }
  }
}

.custom-modal {
  .ant-modal-content {
    border-radius: 12px;
    .ant-modal-close-icon {
      font-size: 18px;
    }
    .ant-modal-body {
      padding-top: 16px;
      .ant-modal-confirm-title {
        font-size: 18px;
      }
    }
  }
}

.ant-dropdown-menu {
  padding: 0 !important;
  border-radius: 16px !important;
  overflow: hidden;
  .ant-dropdown-menu-item {
    font-size: 16px;
    font-weight: 700;
    width: 160px;
    height: 38px;
    .ant-dropdown-menu-title-content {
      text-align: center;
    }
    a {
      color: #2ba245 !important;
    }
  }
}

.orderListWrap {
  margin: 8px -32px;
  padding: 0 16px;
  max-height: 50vh;
  overflow-y: auto;
  .order-item {
    border: 1px solid #f1f1f1;
    background-color: #f1f1f1;
    color: #666;
    font-size: 12px;
    padding: 14px;
    border-radius: 12px;
    margin-bottom: 16px;
    .order-item-col {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .order-item-col-left {
        flex: 1;
        display: flex;
        align-items: center;
        .order-item-col-label {
          flex: 0 0 124px;
        }
        .order-item-col-val {
          flex: 1;
        }
      }
      .order-item-col-right {
        font-weight: 700;
        color: #000;
      }
    }
  }
}
.delAccount-modal {
  .ant-modal-confirm-btns {
    display: none;
  }
  .checkBox {
    margin-top: 24px;
    display: inline-block;
    a {
      display: inline;
      color: #2ba245;
    }
  }
  .delBtn {
    margin-top: 24px;
    display: inline-block;
    float: right;
    color: #fff;
    border-color: red;
    background: red;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    height: 32px;
    padding: 4px 15px;
    cursor: pointer;
  }
}
.userInfo-modal {
  .ant-modal-confirm-btns {
    display: none;
  }
  .upgrade-btn {
    margin-top: 24px;
    text-align: right;
    button {
      cursor: pointer;
      background: #2ba245;
      border-color: #2ba245;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
      color: #fff;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
      outline: 0;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      height: 32px;
      line-height: 1.5715;
      padding: 4px 15px;
      position: relative;
      text-align: center;
      touch-action: manipulation;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      &:hover {
        background: #4cb05e;
        border-color: #4cb05e;
        color: #fff;
      }
    }
  }
  .del-btn {
    margin-top: 12px;
    text-align: right;
    .delAccount-btn {
      cursor: pointer;
      color: #898989 !important;
      font-size: 12px;
      line-height: 24px;
      background-color: #efefef;
    }
  }
}

@primary-color: #2ba245;