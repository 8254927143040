.footer-container {
  background: #2ba245;
  color: #fff;
  padding-bottom: 12px;
  .footer-bottom {
    .footer-content {
      padding-top: 48px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .footer-content-item {
        margin-bottom: 16px;
        .fci-title {
          font-size: 16px;
          font-weight: 700;
        }
        .fci-list {
          margin-top: 8px;
          .fcil-item {
            color: rgba(252, 252, 252, 1);
            font-size: 14px;
            a {
              color: inherit;
            }
          }
        }
      }
    }
    .footer-right {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: rgba(252, 252, 252, 1);
    }
  }
}

@media (max-width: 768px) {
  .footer-container {
    .footer-bottom {
      .footer-content {
        .footer-content-item {
          flex-basis: 50%;
          .fci-title {
            font-size: 16px;
          }
          .fci-list {
            .fcil-item {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@primary-color: #2ba245;