.support-container {
  min-height: calc(100vh - 276px - 2rem);
  border-top: 1px solid #2ba245;
  .container {
    margin-top: 5rem;
    .support-title {
      font-size: 2rem;
      margin-bottom: 2rem;
    }
    .submitBtn {
      border-radius: 4px;
      width: 160px;
      font-size: 16px;
      font-weight: 700;
    }
    .contact-us {
      display: flex;
      .label {
        margin-right: 4px;
        margin-bottom: 16px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .support-container {
    min-height: calc(100vh - 360px - 4rem);
    .container {
      margin-top: 5rem;
      .support-title {
        font-size: 5rem;
      }
    }
  }
}

@primary-color: #2ba245;