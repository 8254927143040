body,
html,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
header,
menu,
section,
p,
input,
td,
th,
ins {
  padding: 0;
  margin: 0;
}

a {
  color: #333;
  text-decoration: none;
  display: block;
}

img {
  vertical-align: top;
}

input, textarea, button, select, a {
  outline: none;
  border: none;
}

li, ul {
  list-style: none;
}
